import React from "react";
import {
  Paper,
  List,
  ListItem,
  Typography,
  Button,
  Grid,
  makeStyles,
  TextField,
  Link,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useForm, Controller } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import maincolors from "../Colors/Color";

const useStyles = makeStyles((theme) => ({
  meinList: {
    backgroundColor: "#ccd8ee",
    color: "#00416b",
    boxShadow: "none",
    padding: theme.spacing(2),
    marginTop: "1.5rem",
    borderTop: "1px solid #00416b",
    borderRadius: 0,
  },

  buttonSubmit: {
    backgroundColor: "#00416b",
    color: "#fff",
    borderRadius: 0,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "&:hover": {
      color: "#fff",
      backgroundColor: "#00416b",
    },
  },
  listItem: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    paddingBottom: "1rem",
    borderBottom: "1px solid #00416b",
    marginLeft: -15,
    marginRight: -15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  text: {
    textAlign: "start",
    marginLeft: theme.spacing(2),
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  label: {
    paddingBottom: 10,
  },
  button: {
    justifyContent: "start",
    paddingLeft: 22,
    paddingBottom: 30,
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#ccd8ee",
      borderRadius: 0,
    },
    "& input": {
      backgroundColor: "#fff",
    },
  },
  terms: {
    paddingLeft: 22,
    paddingRight: 30,
  },
  req: {
    paddingLeft: 22,
    paddingRight: 30,
  },
  closeicon: {
    float: "right",
    paddingRight: theme.spacing(2),
    paddingTop: 16,
    cursor: "pointer",
    color: maincolors.darkblue,
    fontSize: "2rem",
  },
  checkboxLabel: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 36,
    },
    "& a": {
      color: "#00416b",
    },
  },
}));
const NAME_REGEXP = /^[^\d"@!?/|<>]+$/i;

const Cost = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [showForm, setShowForm] = React.useState(true);
  const initialMessage =
    "Inserisci i tuoi dati per ricevere la brochure via email";
  // Message to display instead of the form
  const [message, setMessage] = React.useState(initialMessage);

  const handleClickOpen = () => {
    setOpen(true);
    setShowForm(true);
    setMessage(initialMessage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const doSubmitForm = (data) => {
    setShowForm(false);
    fetch("/submit_brochure_request", {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data),
    })
      .then((res) => {
        if (res.status < 400) {
          setMessage(`Ti abbiamo inviato la brochure per email`);
        } else {
          console.error(`A server error occurred: ${res.status}`);
          setMessage("Siamo spiacenti, si è verificato un errore");
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage(`Si è verificato un errore. Si prega di riprovare`);
        setShowForm(true);
      });
  };

  return (
    <div>
      <Paper className={classes.meinList}>
        <List>
          <div className={classes.listItem}>
            <ListItem>Inizio del corso</ListItem>
            <Typography className={classes.text} color="initial">
            5 aprile 2024
            </Typography>
          </div>

          <div className={classes.listItem}>
            <ListItem>Calendario</ListItem>
            <Typography className={classes.text} color="initial">
            6 mesi, 5 aprile - 13 luglio e 6 settembre - 15 dicembre
            </Typography>
          </div>
          <div className={classes.listItem}>
            <ListItem>Durata</ListItem>
            <Typography className={classes.text} color="initial">
              120 ore
            </Typography>
          </div>
          <div className={classes.listItem}>
            <ListItem>Lezioni</ListItem>
            <Typography className={classes.text} color="initial">
              venerdì h15-18, sabato h10-13, tre fine settimana al mese
            </Typography>
          </div>
          <div className={classes.listItem}>
            <ListItem>Modalità</ListItem>
            <Typography className={classes.text} color="initial">
              da remoto, con limitata partecipazione in sede a rotazione. Previste almeno due lezioni in presenza con tutti gli studenti a Roma.
            </Typography>
          </div>
          <div className={classes.listItem}>
            <ListItem>Lingua</ListItem>
            <Typography className={classes.text} color="initial">
              italiano, con alcuni interventi in lingua inglese degli ospiti stranieri            </Typography>
          </div>
          <div className={classes.listItem}>
            <ListItem>Prezzo</ListItem>
            <Typography className={classes.text} color="initial">
             
              <ul className="price-list">
                <li>€5.500 in due rate da €2.750 (marzo e settembre)</li>
                <li>
                  €4.950 (sconto del 10%) con pagamento in unica rata (marzo) con abbonamento omaggio per 12 mesi a Limes (rivista e sito)
                </li>
              </ul>
              {/* Scopri{" "}
              <a
                className="link_color"
                target="_blank"
                href="https://bnl.it/it/Individui-e-Famiglie/Prestiti/Prestito-BNL-Futuriamo?utm_sourc[…]s&utm_medium=partnership&utm_campaign=young&utm_content=futuriamo"
              >
                qui
              </a>{" "}
              come accedere alle forme di finanziamento offerte da BNL Gruppo
              BNP Paribas */}
            </Typography>
          </div>

          {/* <div className={classes.listItem}>
            <ListItem>Costo</ListItem>
            <Typography className={classes.text} color="initial">
              4.500.00 €
            </Typography>
          </div> */}
        </List>
        <Link className={classes.link}>
          <Button
            variant="contained"
            color="default"
            className={classes.buttonSubmit}
            onClick={handleClickOpen}
          >
            Richiedi LA BROCHURE
          </Button>
        </Link>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <CloseIcon className={classes.closeicon} onClick={handleClose} />
            <DialogTitle id="form-dialog-title">{message}</DialogTitle>
          </div>
          {showForm ? (
            getForm({
              onSubmit: handleSubmit(doSubmitForm),
              classes,
              control,
              errors,
              value,
              handleChange,
              register,
            })
          ) : (
            <React.Fragment />
          )}
        </Dialog>
      </Paper>
    </div >
  );
};

function getForm({
  onSubmit,
  classes,
  control,
  errors,
  value,
  handleChange,
  register,
}) {
  return (
    <form
      id="form-dialog"
      action="/"
      method="POST"
      encType="multipart/form-data"
      onSubmit={onSubmit}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <label className={classes.label}>Nome *</label>
            <TextField
              fullWidth
              className={classes.input}
              type="text"
              name="firstname"
              required={true}
              variant="outlined"
              placeholder="Inserisci"
              autoFocus
              InputLabelProps={{ shrink: false }}
              inputRef={register({
                pattern: NAME_REGEXP,
              })}
            />
            <span className={classes.validation}>
              {errors.firstname &&
                'Il nome non può contenere numeri o " @  ! ? / | < >'}
            </span>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label className={classes.label}>Cognome *</label>
            <TextField
              fullWidth
              className={classes.input}
              type="text"
              name="lastname"
              required={true}
              variant="outlined"
              placeholder="Inserisci"
              inputRef={register({
                pattern: NAME_REGEXP,
              })}
            />
            <span className={classes.validation}>
              {errors.lastname &&
                'Il cognome non può contenere numeri o " @  ! ? / | < >'}
            </span>
          </Grid>

          <Grid item sm={12} xs={12}>
            <label className={classes.label}>Email *</label>
            <TextField
              fullWidth
              required={true}
              type="email"
              name="email"
              className={classes.input}
              variant="outlined"
              inputRef={register()}
              placeholder="Inserisci"
              InputLabelProps={{ shrink: false }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Typography className={classes.req}>* Campi obbligatori</Typography>
      <div className={`terms-forms ${classes.terms}`}>
        <label>
          <span>
            Proseguendo, dichiari di aver letto e compreso{" "}
            <a className={classes.labellink} href="./brochure">
              l'informativa sulla privacy
            </a>
          </span>
        </label>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              required={false}
              name="GEDI_accepted"
              value="GEDI_accepted"
              color="primary"
              inputRef={register()}
            />
          }
          label={
            <span>
              Cliccando qui presti il consenso a che GEDI Gruppo Editoriale
              S.p.A. e le sue società controllate trattino i tuoi dati personali
              per l’invio di comunicazioni commerciali inerenti alle attività
              della Scuola di Limes e ai propri prodotti e servizi.
            </span>
          }
        />
      </div>
      <DialogActions className={classes.button}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.buttonSubmit}
        >
          Invia
        </Button>
      </DialogActions>
    </form>
  );
}

export default Cost;
