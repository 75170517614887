import React from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";

import Rectangle from "../Layout/Rectangle.jsx";
import DocintiImages from "../../helpers/DocintiImages";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,

      marginBottom: 16,
      "& svg": {
        float: "right",
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 400,
  },
  root: {
    boxShadow: "none",
    borderRadius: 0,
  },
  cardContent: {
    padding: "16px 0",
    paddingBottom: 60,
  },
  text: {
    paddingTop: "1rem",

    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  container: {
    marginTop: "1rem",
  },
  description: {
    paddingTop: "1rem",
  },
}));

const TUTTI_I_DOCENTI = [
  {
    id: "5",
    firstname: "Rosario",
    lastname: "Aitala",
    auth: "Magistrato",
    image: DocintiImages["aitala.jpg"].default,
    description:
      "Giudice della Corte Penale Internazionale. Ha lavorato da magistrato a Milano, Trapani e Roma e ha vissuto in Albania, Afghanistan e America Latina occupandosi di diplomazia, sicurezza, mafie e terrorismi.",
  },
  {
    id: "6",
    firstname: "Giuseppe",
    lastname: "Cucchi",
    auth: "Generale",
    image: DocintiImages["Cucchi.jpg"].default,
    description:
      "Generale, già direttore del Dipartimento delle informazioni per la sicurezza, dal 1991 al 1997 direttore del Centro militare di studi strategici, è stato consigliere militare della Presidenza del Consiglio dei ministri.",
  },
  {
    id: "10",
    firstname: "Paolo",
    lastname: "Peluffo",
    auth: "Storico e dirigente pubblico",
    image: DocintiImages["peluffo.jpg"].default,
    description:
      "Segretario generale del Consiglio nazionale dell’economia e del lavoro. Storico del Risorgimento, è stato consigliere del Presidente della Repubblica Carlo Azeglio Ciampi.",
  },
  {
    id: "11",
    firstname: "Francesco",
    lastname: "Sisci",
    auth: "Sinologo",
    image: DocintiImages["sisci.jpg"].default,
    description:
      "Professore associato all’Università del popolo cinese. Segue da vicino i rapporti tra Pechino e Vaticano. È stato il primo a intervistare papa Francesco sulle relazioni con la Cina.",
  },
  {
    id: "12",
    firstname: "George",
    lastname: "Friedman (Usa)",
    auth: "Analista geopolitico",
    image: DocintiImages["friedmann.jpg"].default,
    description:
      "Tra i massimi esperti di geopolitica, è fondatore e amministratore delegato di Geopolitical Futures. Già fondatore di Stratfor, ha lavorato a lungo negli apparati statunitensi, specie nell’Office of Net Assessment.",
  },
  {
    id: "13",
    firstname: "Jacob",
    lastname: "Shapiro (Usa)",
    auth: "Analista geopolitico",
    image: DocintiImages["shapiro.jpg"].default,
    description:
      "Analista geopolitico, è fondatore di Perch Perspectives. In passato è stato capo dell’analisi presso Geopolitical Futures.",
  },
  {
    id: "15",
    firstname: "You",
    lastname: "Ji (Cina)",
    auth: "Analista geopolitico",
    image: DocintiImages["youji.jpg"].default,
    description:
      "Capo del dipartimento di Governo e pubblica amministrazione all’Università di Macao. Studioso della geostrategia e degli affari militari della Repubblica Popolare di Cina.",
  },
  {
    id: "16",
    firstname: "Sergej",
    lastname: "Karaganov (Russia)",
    auth: "Analista geopolitico",
    image: DocintiImages["karaganov.jpg"].default,
    description:
      "Rettore della facoltà di Economia mondiale e Affari internazionali dell’Alta scuola di economia a Mosca. È stato consigliere del primo ministro Evgenij Primakov e dei presidenti Boris El’cin e Vladimir Putin. È fra i principali sostenitori dell’orientamento eurasiatico in Russia.",
  },
  {
    id: "17",
    firstname: "Dmitrij",
    lastname: "Trenin (Russia)",
    auth: "Analista geopolitico",
    image: DocintiImages["trenin.jpg"].default,
    description:
      "Direttore del Centro Carnegie di Mosca, membro dell’Accademia reale svedese per le scienze militari. Già colonnello della Direzione intelligence delle Forze armate dell’Unione Sovietica e poi della Federazione Russa.",
  },
  {
    id: "18",
    firstname: "Heribert",
    lastname: "Dieter (Germania)",
    auth: "Analista geopolitico",
    image: DocintiImages["dieter.jpg"].default,
    description:
      "Professore in visita presso l’Asia Global Institute dell’Università di Hong Kong. Dal 2001 membro dell’Istituto per gli affari internazionali e di sicurezza di Berlino. Studioso della geoeconomia tedesca, della natura strategica della politica industriale e dei legami tra Germania e Cina.",
  },
  {
    id: "19",
    firstname: "Pierre-Emmanuel",
    lastname: "Thomann (Francia)",
    auth: "Analista geopolitico",
    image: DocintiImages["thomann.jpg"].default,
    description:
      "Dottore in geopolitica, allievo dell’Institut français de géopolitique, presidente di Eurocontinent, fornisce consulenze alle istituzioni dell’Unione Europea.",
  },
  {
    id: "20",
    firstname: "Abdolrasool",
    lastname: "Divsallar (Iran)",
    auth: "Analista geopolitico",
    image: DocintiImages["divsalla.jpg"].default,
    description:
      "Insegna e fa ricerca presso il Centro Robert Schuman per gli studi avanzati, l’Università Cattolica e lo European University Institute di Firenze. Ha lavorato presso il Centro di studi strategici di Teheran guidato all’epoca dall’attuale presidente della Repubblica Islamica d’Iran, Hassan Rohani.",
  },
  {
    id: "21",
    firstname: "Franco",
    lastname: "Bernabè",
    auth: "Manager e imprenditore",
    image: DocintiImages["Bernabè.jpg"].default,
    description:
      "Presidente di Cellnex, il più importante operatore indipendente europeo di infrastrutture di telecomunicazioni mobili. È stato a.d. di Eni e Telecom Italia.",
  },
];

const sorteddocents = TUTTI_I_DOCENTI.sort(function (a, b) {
  return a.lastname.localeCompare(b.lastname);
});

const TUTTI_I_DOCENTISection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          Le testimonianze
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.description}>
              Analisti italiani e stranieri della rete di Limes, dirigenti e
              funzionari di Stato provenienti dalle strutture di governo,
              dall’intelligence, dalle Forze armate, protagonisti del mondo
              economico-finanziario.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className={`flip-cards-container ${classes.container}`}
        >
          {sorteddocents.map((docent) => {

            return (
              <Grid
                item
                key={docent.firstname}
                md={3}
                sm={6}
                xs={12}
                className="flip-card flip-cards-4"
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={docent.image}
                      className={classes.image}
                      title="Image title"
                    />
                    <Typography variant="h5" className="authors-name">
                      {docent.firstname}
                    </Typography>
                    <Typography variant="h5" className="authors-name">
                      {docent.lastname}
                    </Typography>
                    <Typography gutterBottom className="card-title">
                      {docent.auth}
                    </Typography>
                  </div>
                  <div className={`flip-card-back ${classes.cardContent}`}>
                    <Typography className="card-text">
                      {docent.description}{" "}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default TUTTI_I_DOCENTISection;
