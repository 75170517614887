import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  Link,
} from "@material-ui/core";
import maincolors from "../Colors/Color";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useForm, Controller } from "react-hook-form";
import { display } from "@material-ui/system";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    backgroundColor: maincolors.darkblue,
    color: maincolors.white,
    padding: theme.spacing(6, 0, 8, 0),
    display: "flex",
    clear: "both",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 130,
      marginBottom: 0,
    },
  },
  wrapper: {
    width: "100%",
    margin: "0 40px",
    marginBottom: "70px",
    [theme.breakpoints.down("md")]: {
      margin: "0 60px",
      marginBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 40px",
      marginBottom: "140px",
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    borderBottom: "1px solid",
    paddingBottom: theme.spacing(2),
  },
  link: {
    color: maincolors.white,
    textDecoration: "none",
    marginTop: 16,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      float: "left",
      marginTop: 20,
      marginLeft: 8,
    },
  },
  link_privacy: {
    color: maincolors.white,
    fontFamily: "domaineSanTextLight",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      float: "left",
      marginLeft: 0,
    },
  },

  address: {
    marginTop: 60,

    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      float: "left",
    },
  },
  input: {
    width: "66%",
    marginBottom: 15,
    background: maincolors.white,
    "& div": {
      borderRadius: 0,
      height: 60,
    },
  },
  newsletter: {
    paddingBottom: "1rem",
    display: "block",
    textTransform: "uppercase",
  },
  section: {
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },
  news: {
    color: "#fff",
    fontWeight: 700,
  },
  button: {
    background: "#fff",
    color: "#00406c",
    borderRadius: 0,
    fontWeight: 600,
    height: 60,
    marginLeft: 15,
    padding: "18.5px 14px",
    "&:hover": {
      background: "#fff",
    },
  },
  cookie: {
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  bannerText: {
    clear: "both",
    padding: "4px 16px",
    display: "block",
  },
  number: {
    marginTop: 20,
  },
  document: {
    marginTop: 30,
  },
  validation: {
    display: "block",
  },
}));

const onSubmit = (data) => {
  var isValid = true; // TODO: perform validation
  if (!isValid) {
    e.preventDefault();
  }

  fetch("/", {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  })
    .then((res) => {
      if (res.status < 400) {
        console.log(res.status);
      } else {
        console.error(`A server error occurred: ${res.status}`);
        alert("Siamo spiacenti, si è verificato un errore");
      }
    })
    .catch((err) => {
      console.error(err);
      alert(
        "Siamo spiacenti, si è verificato un errore di connessione. La preghiamo di riprovare più tardi"
      );
    });
};

const Footer = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  return (
    <div className={classes.grow} id="footer">
      <div className={classes.wrapper}>
        <Typography
          variant="h3"
          color="initial"
          className={classes.title}
          id="footer-title"
        >
          SCUOLA DI LIMES
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            <Typography
              variant="h5"
              color="initial"
              className={`footer-subtitle ${classes.newsletter}`}
            >
              Sede Operativa
            </Typography>
            <Typography className={`footer-text ${classes.section}`}>
              Via Cristoforo Colombo 90 <br />
              00147 Roma RM
            </Typography>

            <Typography
              variant="h5"
              color="initial"
              className={`footer-subtitle ${classes.newsletter}`}
            >
              CONTATTI
            </Typography>
            <Typography color="initial" className="footer-text">
              Mail: <br /> info@scuoladilimes.it
            </Typography>
            <div className="social-media-icons">
              <Link href="https://www.linkedin.com/school/scuola-limes-geopolitica/" target="_blank">
                <LinkedInIcon />
              </Link>
              <Link href="https://www.instagram.com/scuoladilimes/" target="_blank">
                <InstagramIcon />
              </Link>
            </div>

            <Typography
              className={`footer-subtitle ${classes.number}`}
              color="initial"
            >
              Telefono: <br /> +39 06 49822803
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Typography
              variant="h5"
              color="initial"
              className={`footer-subtitle ${classes.newsletter}`}
            >
              NEWSLETTER
            </Typography>
            <Typography color="initial" className="footer-text">
              Iscriviti alla newsletter gratuita di Limes per rimanere
              aggiornato sulle attività e sulla produzione della rivista.{" "}
              <a
                className={classes.news}
                target="_blank"
                href={
                  "https://www.limesonline.com/edicola/funnel/login.jsp?origin=newsletter&urlToken=&ssoOnly=false&backurl=https%3A%2F%2Fwww.limesonline.com%2Fsocial%2Fsites%2Flimesonline%2Fwww%2Floader.php%3Forigin%3Dnewsletter%26urlToken%3D%26mClose%3D2%26backUrl%3Dhttps%253A%2F%2Fwww.limesonline.com%2Fedicola%2Fprofilo%2Fnewsletter.jsp%253Ftestata%253Dlimes&optbackurl=https%3A%2F%2Fwww.limesonline.com%2Fsocial%2Fsites%2Flimesonline%2Fwww%2Floader.php%3Forigin%3Dnewsletter%26urlToken%3D%26mClose%3D2%26backUrl%3Dhttps%253A%2F%2Fwww.limesonline.com%2Fedicola%2Fprofilo%2Fnewsletter.jsp%253Ftestata%253Dlimes"
                }
              >
                Clicca qui.
              </a>
            </Typography>
            <br />

          </Grid>
        </Grid>
      </div>
      <Grid container className={`footer-banner`}>
        <div className="banner-links">
          <a href="./paginadocumenti">Informativa e cookie policy</a>
          <a href="./condizionidelservizio">Condizioni di servizio</a>
          <a className="underline" href="./candidature">
            Privacy
          </a>
        </div>
        <p className={classes.bannerText}>
          GEDI Periodici e Servizi S.p.A. • P. Iva 12546800017  – GEDI Digital
          S.r.l. • P.Iva 06979891006
        </p>
      </Grid>
    </div>
  );
};

export default Footer;
