import React from "react";
import CoverImage from "../../components/Homepage/CoverImage.jsx";
import TitoloBlog from "../../components/Homepage/TitoloBlog.jsx";
import MondoBlog from "../../components/Homepage/MondoBlog.jsx";
import CardSection from "../../components/Homepage/CardSection.jsx";
import FormazBlog from "../../components/Homepage/FormazBlog.jsx";
import IlmondoDiLimes from "../../components/Homepage/IlmondoDiLimes.jsx";
import IlClubAlumni from '../../components/Homepage/IlClubAlumni.jsx';
import Partnership from "../../components/Homepage/partnership.jsx";

function Homepage() {
  return (
    <div>
      <CoverImage />
      <TitoloBlog />
      <MondoBlog />
      <CardSection />
      {/* <FormazBlog /> */}
      <IlClubAlumni />
      <Partnership />
      <IlmondoDiLimes />
 
    </div>
  );
}

export default Homepage;
