import React from "react";
import {
  Typography,
  makeStyles,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
}));

function createData(feature, complete, demand) {
  return { feature, complete, demand };
}

const rows = [
  createData("Data inizio", "Aprile 2024", "Ottobre 2024"),
  createData("Ore di lezione", 120, 85),
  createData("Esercitazioni", "✓", "Una, a fine corso"),
  createData(
    "Interazione con i docenti",
    "Ogni lezione è composta da due ore totali di esposizione da parte dei docenti e un'ora di dibattito con gli studenti",
    "🗙"
  ),
  createData(
    "Ricevimenti",
    "Sì, a richiesta",
    "Sì, in tre date"
  ),
  // createData("Contatto con gli altri studenti", "✓", "🗙"),
  createData(
    "Stage",
    "Gli studenti migliori verranno selezionati per stage presso aziende partner",
    "🗙"
  ),
  createData("Attestato", "✓", "🗙"),
  createData("Possibilità di entrare nel club Alumni della scuola", "✓", "🗙"),
  createData(
    "Prezzo",
    "€5.500 in due rate, €4.950 in una rata con abbonamento 12 mesi alla rivista e al sito",
    "€3.000 in un’unica rata con abbonamento di 12 mesi oppure in due rate di €1.500 ciascuna"
  ),
  createData(
    "Incontri in presenza con i docenti",
    "A turni a Roma durante la lezione e un incontro per ogni modulo con evento finale per la consegna dei diplomi",
    "🗙"
  ),
];

const IlCorsoAsincrono = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          IL CORSO ON DEMAND
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Dal mese di ottobre 2024, chi non ha la possibilità di seguire in diretta il corso, potrà acquistarlo al prezzo di €3.000 avendo così a disposizione tutte le lezioni svolte nel corso dell'anno 2024 attraverso la piattaforma dedicata. Questa modalità prevede
              tre ricevimenti con i docenti. L'acquisto del corso non dà accesso alla selezione da parte
              delle aziende partner finalizzata agli stage.
            </Typography>

            <Typography
              className="section-subtitle ilmond-title"
              variant="h5"
              color="initial"
            >
              Tabella di confronto tra il corso "Le Chiavi del Mondo" completo e
              quello in modalità on demand
            </Typography>

            <TableContainer component={Paper} className="table-container">
              <Table className={classes.table} aria-label="simple table">
                <TableHead className="table-header">
                  <TableRow>
                    <TableCell
                      style={{ width: "33%" }}
                      align="left"
                      className="nocolor"
                    />

                    <TableCell style={{ width: "33%" }} align="left">
                      Corso completo
                    </TableCell>

                    <TableCell style={{ width: "33%" }} align="left">
                      {" "}
                      Corso on demand
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.feature}>
                      <TableCell style={{ width: "33%" }} align="left">
                        {row.feature}
                      </TableCell>
                      <TableCell style={{ width: "33%" }} align="left">
                        {row.complete}
                      </TableCell>
                      <TableCell style={{ width: "33%" }} align="left">
                        {row.demand}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IlCorsoAsincrono;
